import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const { heading, options, selectedOptions, withTranslations, intl, sectionId } = props;
  if (!heading || !options || !selectedOptions) {
    return null;
  }

  let labelText = heading

  if (sectionId === "eventType") labelText = intl.formatMessage({ id: 'EventTypes.title' })
  else if (sectionId === "amenities") labelText = intl.formatMessage({ id: 'Amenities.title' })
  else if (sectionId === "equipments") labelText = intl.formatMessage({ id: 'Equipments.title' })
  else if (sectionId === "other_amenities_extras") labelText = intl.formatMessage({ id: 'OtherAmenities.title' })

  return (
    <div className={css.sectionMultiEnum}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {labelText}
      </Heading>
      <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={options.length > 5}
        withTranslations={withTranslations}
        intl={intl}
      />
    </div>
  );
};

export default SectionMultiEnumMaybe;
